import React from 'react'

// Third party
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import Plaatjie from '@ubo/plaatjie'

// Third party CSS
import 'swiper/css'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { SliderProps } from 'components/flex/Slider/SliderShell'

const Section = styled.section`
  position: relative;
  z-index: 2;
`

const Image = styled(Plaatjie)`
  max-width: 224px;
  max-height: 78px;

  & img {
    object-fit: contain !important;
  }

  filter: grayscale(100%);

  &:hover {
    filter: unset;
  }

  @media (max-width: 575.98px) {
    width: 100px;
  }
`

const Slider: React.FC<SliderProps> = ({ fields }) => {
  // Core
  SwiperCore.use([Autoplay])

  return (
    <Section className="mb-5 pb-5">
      <div className="container py-5">
        <div className="row">
          <ParseContent content={fields.description} className="mb-5" />
          <Swiper
            breakpoints={{
              1: {
                slidesPerView: 2,
              },
              375: {
                slidesPerView: 2,
              },
              767: {
                slidesPerView: 3,
              },
              991: {
                slidesPerView: 5,
              },
            }}
            centerInsufficientSlides
          >
            {fields?.slides?.map((slide, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                {slide?.link ? (
                  <SwiperSlide className="d-flex justify-content-center">
                    <a
                      href={slide.link.url}
                      className="swiper-slide d-flex justify-content-center align-items-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        objectFit="contain"
                        objectPosition="center"
                        image={slide?.image}
                        alt=""
                      />
                    </a>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide className="d-flex justify-content-center">
                    <Image image={slide?.image} alt="" />
                  </SwiperSlide>
                )}
              </React.Fragment>
            ))}
          </Swiper>
        </div>
      </div>
    </Section>
  )
}

export default Slider
